import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { styled } from "@emotion/styled";
const cardData = [
  {
    canvaHeight: 100,
    canvaWidth: 225,
    top: "50%",
    left: "37%",
    posX: 117,
    posY: 250,
    src: "/caratlane.svg",
    size: 0.92,
    gltfURL: "/GLTF/UP00622_01.gltf",
    gltfImg: "/GLTF/texture_diff.png",
    canvaHeightRender: 410,
    canvaWidthRender: 920,
    leftOffset: 48,
    bottomOffset: 302,
    id: 1,
  },
  {
    canvaHeight: 200,
    canvaWidth: 225,
    top: "50%",
    left: "37%",
    posX: 385,
    posY: 380,
    src: "/rectangle.svg",
    size: 0.92,
    gltfURL: "/GLTF/UP00622_01.gltf",
    gltfImg: "/GLTF/texture_diff.png",
    canvaHeightRender: 410,
    canvaWidthRender: 920,
    id: 2,
  },
  {
    canvaHeight: 100,
    canvaWidth: 225,
    top: "50%",
    left: "37%",
    posX: 400,
    posY: 520,
    src: "/ring.svg",
    size: 1.2,
    gltfURL: "/GLTF/UP00622_01.gltf",
    gltfImg: "/GLTF/texture_diff.png",
    canvaHeightRender: 410,
    canvaWidthRender: 920,
    id: 3,
  },
];

const CardComponent = () => {
  const nav = useNavigate();

  return (
    <>
      <br />
      <br />
      <br />
      <Grid container justifyContent="center" spacing={3}>
        {cardData.map((data, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              style={{
                width: 280,
                height: 350,
                cursor: "pointer",
                transition: "transform 0.3s, background-color 0.3s", // Add smooth transition effects for transform and background-color
                transform: "scale(1)", // Default scale value
                backgroundColor: "white", // Default background color
              }}
              onClick={() => {
                nav("/product", {
                  state: { Props: data },
                });
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.05)"; // Zoom in on hover
                // e.currentTarget.style.backgroundColor = "#c0a3fc"; // Change background color on hover
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)"; // Restore the default scale when not hovered
                e.currentTarget.style.backgroundColor = "white"; // Restore the default background color when not hovered
              }}
            >
              <CardMedia
                component="img"
                alt={`Image ${index + 1}`}
                // height={data.canvaHeight}
                image={data.src}
              />
              <CardContent>
                <Box display="flex" flexDirection="column" height="100%">
                  <Box flexGrow={1}></Box>
                  <Typography variant="h6" gutterBottom>
                    {data.name}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CardComponent;
