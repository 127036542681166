import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Canvas } from "react-sketch-canvas";
import SignatureCanvas from "react-signature-canvas";
import { useLocation, useNavigate } from "react-router-dom";

const DemoModel = ({ props }) => {
  //   let Props = {
  //     canvaHeight: 100,
  //     canvaWidth: 225,
  //     top: "50%",
  //     left: "37%",
  //     posX: 400,
  //     posY: 520,
  //     src: "/ring.svg",
  //     size: 1.2,
  //   };
  const location = useLocation();
  const Props = location.state?.Props;
  console.log(Props);
  const divStyle = {
    backgroundColor: "white",
    height: "78%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "6%",
  };

  const buttonStyle = {
    width: "140px",
    height: "47px",
    borderRadius: "10px",
    background: "linear-gradient(90deg, #E56EEB 0%, #8863FB 100%)",
    color: "white",
  };

  const backButtonStyle = {
    width: "140px",
    height: "47px",
    borderRadius: "10px",
    background: "#c8c8c863",
    // color: "white",
    color: "#6c636394",
  };

  const nav = useNavigate();
  const sigCanvas = useRef(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [penSize, setPenSize] = useState(3); // Default pen size

  const handlePenSizeChange = (size) => {
    setPenSize(size);
  };

  const combinedCanvasRef = useRef(document.createElement("canvas"));
  const [urlIMG, setImgUrl] = useState(Props.src);

  const handleClear = () => {
    sigCanvas.current.clear();
    setImgUrl(Props.src);
  };

  const handleWrite = () => {
    const signatureDataUrl = sigCanvas.current.toDataURL("image/png");
    console.log(signatureDataUrl);
    // Ensure signatureDataUrl is available
    if (!signatureDataUrl) {
      console.log("Signature data URL is not available.");
      return;
    }

    // Set the signature data URL in state
    setSignatureDataURL(signatureDataUrl);

    // Create a canvas to combine the image and signature
    const combinedCanvas = combinedCanvasRef.current;
    const ctx = combinedCanvas.getContext("2d");

    // Load and draw the "caratlane.svg" image
    const img = new Image();
    img.src = Props.src;

    img.onload = async function () {
      // Calculate the position to center the signature on the image

      // Draw the image on the combined canvas
      combinedCanvas.width = img.width;
      combinedCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      console.log("hey");

      // Draw the signature on top of the image
      const sigImg = new Image();

      sigImg.src = signatureDataUrl;

      sigImg.onload = function () {
        const newWidth = Props.canvaWidth * Props.size;
        const newHeight = Props.canvaHeight * Props.size;

        ctx.drawImage(sigImg, Props.posX, Props.posY, newWidth, newHeight); // Adjust the position and dimensions

        const combinedDataUrl = combinedCanvas.toDataURL("image/png");
        setImgUrl(combinedDataUrl);
        console.log(combinedDataUrl);
      };
    };
  };

  const handlePreview = () => {
    const signatureDataUrl = sigCanvas.current.toDataURL("image/png");
    // console.log(signatureDataUrl);
    // Ensure signatureDataUrl is available
    if (!signatureDataUrl) {
      console.log("Signature data URL is not available.");
      return;
    }

    // Set the signature data URL in state
    setSignatureDataURL(signatureDataUrl);

    // Create a canvas to combine the image and signature
    const combinedCanvas = combinedCanvasRef.current;
    const ctx = combinedCanvas.getContext("2d");

    // Load and draw the "caratlane.svg" image
    const img = new Image();
    img.src = Props.gltfImg;

    img.onload = async function () {
      // Calculate the position to center the signature on the image

      // Draw the image on the combined canvas
      combinedCanvas.width = img.width;
      combinedCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      console.log("hey");

      // Draw the signature on top of the image
      const sigImg = new Image();

      sigImg.src = signatureDataUrl;

      sigImg.onload = function () {
        const newWidth = Props.canvaWidthRender;
        const newHeight = Props.canvaHeightRender;
        const posX = Props.leftOffset;
        const posY = combinedCanvas.height - newHeight - Props.bottomOffset;

        ctx.drawImage(sigImg, posX, posY, newWidth, newHeight); // Adjust the position and dimensions

        const combinedDataUrl = combinedCanvas.toDataURL("image/png");
        handleSaveImage(combinedDataUrl);
      };
    };
  };

  const handleSaveImage = async (imageData) => {
    // Make sure imageData is defined and not an empty string
    if (!imageData) {
      console.error("Invalid image data.");
      return;
    }
    const data = {
      imageData,
    };

    let res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/saveImage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      const responseBody = await res.json(); // Access the response body
      console.log("Response body:", responseBody.gltfUrl);
      const timestamp = Date.now();
      const updatedGltfUrl = `${responseBody.gltfUrl}?timestamp=${timestamp}`;
      nav("/model-view", { state: { Props: Props, Res: updatedGltfUrl } });
    } else {
      console.error("Failed to save image. Status:", res.status);
    }
  };
  return (
    <Grid container style={{ height: "100%", padding: "16px" }}>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        {/* Second div, 70% width on tablets */}
        <div style={divStyle}>
          <img
            src={urlIMG}
            alt="model"
            width="340px"
            height="auto"
            style={{ marginTop: "4%" }}
          />
          <SignatureCanvas
            ref={sigCanvas}
            penColor="#c39747" // Gold pencil color
            minWidth={penSize} // Set the pen size based on the selected value
            maxWidth={penSize}
            canvasProps={{
              width: 562.5,
              height: 250,
              style: {
                background: "transparent", // Make the canvas transparent
                position: "absolute", // Position over the image
                top: "12%",
                right: "2%",
                border: "1px solid #cba16833", // Border color with transparency
                borderRadius: "5px",
                // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", // Depth effect shadow
              },
            }}
          ></SignatureCanvas>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                alignSelf: "end",
                position: "absolute",
                top: "5%",
                right: "2%",
                gap: "20%",
              }}
            >
              <span style={{ marginRight: "10px" }}>Pensize:</span>
              <label style={{ color: "grey" }}>
                <input
                  type="radio"
                  name="penSize"
                  checked={penSize === 1}
                  onChange={() => handlePenSizeChange(1)}
                />
                Small
              </label>
              <label style={{ color: "grey" }}>
                <input
                  type="radio"
                  name="penSize"
                  checked={penSize === 3}
                  onChange={() => handlePenSizeChange(3)}
                />
                Medium
              </label>
              <label style={{ color: "grey", marginRight: "40px" }}>
                <input
                  type="radio"
                  name="penSize"
                  checked={penSize === 5}
                  onChange={() => handlePenSizeChange(5)}
                />
                Large
              </label>
              {/* </div> */}
              <Button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "5px",
                  background: "#c8c8c863",
                  // color: "white",
                  color: "#6c636394",

                  padding: "8px",
                }}
                onClick={handleClear}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Inria Serif",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 200,
                    lineHeight: "normal",
                  }}
                >
                  Reset
                </Typography>
              </Button>

              <Button
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "5px",
                  background: "#c4b45f",
                  // color: "white",
                  color: "white",

                  padding: "8px",
                  marginLeft: "20px",
                }}
                onClick={handleWrite}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Inria Serif",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: 200,
                    lineHeight: "normal",
                  }}
                >
                  Write
                </Typography>
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "end",
            display: "flex",
            gap: "5%",
            width: "900px",
            marginTop: "20%",
            justifyContent: "end",
          }}
        >
          <Button
            style={backButtonStyle}
            onClick={() => {
              nav("/product", { state: { Props: Props } });
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "Inria Serif",
                fontSize: "19px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Back
            </Typography>
          </Button>
          <Button style={buttonStyle} onClick={handlePreview}>
            <Typography
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Inria Serif",
                fontSize: "19px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Preview
            </Typography>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default DemoModel;
