import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
const Item = () => {
  const divStyle = {
    backgroundColor: "white",
    height: "78%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const buttonStyle = {
    width: "218px",
    height: "80px",
    borderRadius: "20px",
    background: "linear-gradient(90deg, #E56EEB 0%, #8863FB 100%)",
    color: "white",
    marginTop: "20%",
  };
  const nav = useNavigate();
  const location = useLocation();

  const Props = location.state?.Props;
  console.log(Props);

  return (
    <Grid
      container
      style={{ height: "70vh", padding: "16px", marginTop: "10vh" }}
    >
      <Grid item xs={12} sm={8} md={8} lg={8}>
        {/* Second div, 70% width on tablets */}
        <div style={divStyle}>
          <img src={Props.src} alt="model" width="500px" height="auto" />
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        {/* Third div, 30% width on tablets */}
        <div style={divStyle}>
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Inria Serif",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              marginTop: "30px",
            }}
            variant="h4"
          >
            Add Engraving
          </Typography>
          <Button
            style={buttonStyle}
            onClick={() => {
              if (Props.id === 1) {
                nav("/model", { state: { Props: Props } });
              } else {
                nav("/draw-v2", { state: { Props: Props } });
              }
            }}
          >
            <Typography
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Inria Serif",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Static
            </Typography>
          </Button>
          <Button
            style={buttonStyle}
            onClick={() => {
              if (Props.id === 1) {
                nav("/model", { state: { Props: Props } });
              } else {
                nav("/draw-v2", { state: { Props: Props } });
              }
            }}
          >
            <Typography
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Inria Serif",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Draw
            </Typography>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Item;
