import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log(process.env.REACT_APP_STATUS);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <center>
        <div
          style={{
            borderRadius: "5px",
            background:
              "linear-gradient(90deg, rgba(229, 110, 235, 0.60) 0%, rgba(136, 99, 251, 0.60) 100%)",
            width: "1024px",
            height: "768px",
          }}
        >
          <div
            style={{
              borderRadius: "20px",
              width: "960px",
              height: "700px",
              background: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div>
              <img
                onClick={() => {
                  window.location.href = "/";
                }}
                src="/Caratlane-logo.png"
                alt="logo"
                style={{
                  width: "220px",
                  height: "auto",
                  position: "absolute",
                  top: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />{" "}
            </div>
            <br />
            <div
              style={{
                borderRadius: "20px",
                width: "960px",
                height: "600px",
                background: "white",
                position: "absolute",
                top: "58%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <App />
            </div>
          </div>
        </div>
      </center>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
