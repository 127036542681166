import React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import Item from "./components/productItem";
import Draw from "./components/draw";
import DrawV2 from "./components/drawv2";
import DrawV3 from "./components/iterate";
import CardComponent from "./pages/home";
// import Model3D from "./components/3dMode";
import DisplayModel from "./components/DisplayModel";
import DemoModel from "./components/DemoModel";

const App = () => {
  return (
    <div>
      {/* <Home /> */}

      <Routes>
        <Route path="/" element={<CardComponent />}></Route>
        <Route path="/product" element={<Item />}></Route>
        <Route path="/model" element={<DemoModel />}></Route>
        <Route path="/model-view" element={<DisplayModel />}></Route>

        <Route path="/draw" element={<Draw />}></Route>
        <Route path="/draw-v2" element={<DrawV3 />}></Route>
      </Routes>
    </div>
  );
};

export default App;
