import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const DisplayModel = ({ props }) => {
  const [gltfUrl, setGltfUrl] = useState("");
  const location = useLocation();
  const Props = location.state?.Props;
  const Res = location.state?.Res;
  //   const key = location.state?.key;

  console.log(Res);
  console.log(Props);
  const divStyle = {
    backgroundColor: "white",
    height: "78%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "6%",
  };

  const buttonStyle = {
    width: "140px",
    height: "47px",
    borderRadius: "10px",
    background: "linear-gradient(90deg, #E56EEB 0%, #8863FB 100%)",
    color: "white",
  };

  const backButtonStyle = {
    width: "140px",
    height: "47px",
    borderRadius: "10px",
    background: "#c8c8c863",
    // color: "white",
    color: "#6c636394",
  };

  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const loadAvatarFile = () => {
      const loader = new GLTFLoader();

      loader.load(
        Res,
        (gltf) => {
          console.log(gltf);
          setGltfUrl(Res);

          setIsLoading(false);
        },
        (xhr) => {
          const progress = (xhr.loaded / xhr.total) * 100;
          console.log(progress);
        },
        (error) => {
          console.log("Error loading avatar file:", error);
        }
      );
    };

    loadAvatarFile();
  }, []);
  return (
    // <Grid container style={{ height: "100%", padding: "16px" }}>
    <div>
      <div style={{ height: "500px" }}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <model-viewer
            key={0}
            style={{ height: "100%", width: "100%" }}
            src={gltfUrl}
            ar
            ar-placement="wall"
            camera-controls
            touch-action="pan-y"
            alt="A 3D model of some wall art"
          ></model-viewer>
        )}
      </div>
      <div
        style={{
          alignSelf: "end",
          display: "flex",
          gap: "5%",
          width: "900px",
          marginTop: "2%",
          justifyContent: "end",
        }}
      >
        <Button
          style={backButtonStyle}
          onClick={() => {
            nav("/model", { state: { Props: Props } });
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontFamily: "Inria Serif",
              fontSize: "19px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Back
          </Typography>
        </Button>
        {/* <Button
          style={buttonStyle}
          //  onClick={handlePreview}
        >
          <Typography
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Inria Serif",
              fontSize: "19px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Preview
          </Typography>
        </Button> */}
      </div>
    </div>
  );
};

export default DisplayModel;
