import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Canvas } from "react-sketch-canvas";
import SignatureCanvas from "react-signature-canvas";

const Draw = ({ props }) => {
  let Props = {
    height: 100,
    width: 225,
    top: "50%",
    left: "37%",
    posX: 117,
    posY: 250,
  };
  const divStyle = {
    backgroundColor: "white",
    height: "78%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6%",
  };

  const buttonStyle = {
    width: "140px",
    height: "47px",
    borderRadius: "10px",
    background: "linear-gradient(90deg, #E56EEB 0%, #8863FB 100%)",
    color: "white",
  };

  const backButtonStyle = {
    width: "140px",
    height: "47px",
    borderRadius: "10px",
    background: "#c8c8c863",
    // color: "white",
    color: "#6c636394",
  };

  const sigCanvas = useRef(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);

  const combinedCanvasRef = useRef(document.createElement("canvas"));
  const [urlIMG, setImgUrl] = useState(null);

  const handleClear = () => {
    sigCanvas.current.clear();
  };

  const handlePreview = () => {
    const signatureDataUrl = sigCanvas.current.toDataURL("image/png");
    console.log(signatureDataUrl);
    // Ensure signatureDataUrl is available
    if (!signatureDataUrl) {
      console.log("Signature data URL is not available.");
      return;
    }

    // Set the signature data URL in state
    setSignatureDataURL(signatureDataUrl);

    // Create a canvas to combine the image and signature
    const combinedCanvas = combinedCanvasRef.current;
    const ctx = combinedCanvas.getContext("2d");

    // Load and draw the "caratlane.svg" image
    const img = new Image();
    img.src = "/caratlane.svg";

    img.onload = async function () {
      // Calculate the position to center the signature on the image

      // Draw the image on the combined canvas
      combinedCanvas.width = img.width;
      combinedCanvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      console.log("hey");

      // Draw the signature on top of the image
      const sigImg = new Image();

      sigImg.src = signatureDataUrl;

      sigImg.onload = function () {
        const newWidth = Props.width * 0.92;
        const newHeight = Props.height * 0.92;

        ctx.drawImage(sigImg, Props.posX, Props.posY, newWidth, newHeight); // Adjust the position and dimensions

        const combinedDataUrl = combinedCanvas.toDataURL("image/png");
        setImgUrl(combinedDataUrl);
        console.log(combinedDataUrl);
      };
    };
  };

  return (
    <Grid container style={{ height: "100%", padding: "16px" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/* Second div, 70% width on tablets */}
        <div style={divStyle}>
          <img src="/caratlane.svg" alt="model" width="500px" height="auto" />
          <SignatureCanvas
            ref={sigCanvas}
            penColor="#c39747" // Gold pencil color
            canvasProps={{
              width: Props.width,
              height: Props.height,
              style: {
                background: "transparent", // Make the canvas transparent
                position: "absolute", // Position over the image
                top: Props.top,
                left: Props.left,
                border: "1px solid #cba16833", // Border color with transparency
                borderRadius: "5px",
                // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)", // Depth effect shadow
              },
            }}
          ></SignatureCanvas>
          <div
            style={{
              alignSelf: "end",
              position: "absolute",
              top: "5%",
              right: "2%",
              gap: "20%",
            }}
          >
            <Button
              style={{
                width: "auto",
                height: "auto",
                borderRadius: "5px",
                background: "#c8c8c863",
                // color: "white",
                color: "#6c636394",

                padding: "8px",
              }}
              onClick={handleClear}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Inria Serif",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 200,
                  lineHeight: "normal",
                }}
              >
                Reset
              </Typography>
            </Button>
          </div>

          <div style={{ alignSelf: "end", display: "flex", gap: "20%" }}>
            <Button style={backButtonStyle} onClick={handleClear}>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "Inria Serif",
                  fontSize: "19px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Back
              </Typography>
            </Button>
            <Button style={buttonStyle} onClick={handlePreview}>
              <Typography
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Inria Serif",
                  fontSize: "19px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Preview
              </Typography>
            </Button>
          </div>
        </div>
      </Grid>
      <center>
        <br />
        <br />
        {urlIMG && <img src={urlIMG} alt="trst" />}
        <model-viewer
          key={0}
          style={{ height: "100%", width: "50%" }}
          src="/GLTF/Pendant.gltf"
          ar
          ar-placement="wall"
          camera-controls
          touch-action="pan-y"
          alt="A 3D model of some wall art"
        ></model-viewer>
      </center>
    </Grid>
  );
};

export default Draw;
